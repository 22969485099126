import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/Layout';
import BreadcrumbSchema from '../components/BreadcrumbSchema';
import Breadcrumbs from '../components/Breadcrumbs';
import { Helmet } from 'react-helmet';
import { FaPhoneAlt, FaClock } from 'react-icons/fa';

const ContactPage = () => {
  const title =
    '当事務所へのお問い合わせはこちら｜奈良のホームページ制作なら｜インヴォルブ';
  const description =
    'お気軽にお問い合わせください。お問い合わせの内容を下のフォームより記載し送信お願いいたします。原則1営業日以内に担当者より折り返しのご連絡をいたします。また平日10時〜19時はお電話のお問い合わせも承っておりますので、どうぞお気軽にお問い合わせください。オンライン会議にも対応しております。';

  const breadcrumbList = [
    { name: 'ホーム', url: 'https://www.involve-in.jp/' },
    {
      name: '当事務所へのお問い合わせはこちら',
      url: 'https://www.involve-in.jp/contact',
    },
  ];

  return (
    <Layout>
      <BreadcrumbSchema breadcrumbList={breadcrumbList} />
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.involve-in.jp/contact/" />
        <link
          rel="icon"
          type="image/x-icon"
          href="/images/common/favicon.ico"
        />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.involve-in.jp/contact" />
        <meta
          property="og:image"
          content="https://www.involve-in.jp/images/common/ogp.png"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta
          name="twitter:image"
          content="https://www.involve-in.jp/images/common/ogp.png"
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
        />
      </Helmet>

      <section className="max-w-screen-lg w-full px-8 md:px-16 mx-auto py-32 bg-white bg-opacity-50">
        <h1 className="font-roboto text-6xl md:text-[10rem] font-bold text-center mb-12">
          Contact
        </h1>
        <h2 className="text-3xl font-bold mb-6 text-center">お問い合わせ</h2>

        {/* 📖 FAQページへの内部リンク（max-w-screen-lg） */}
        <div className="max-w-screen-lg mx-auto mb-6 text-center">
          <p className="text-2xl font-semibold">
            お問い合わせの前に、よくある質問もご確認ください。
          </p>
          <Link
            to="/faq"
            className="text-black font-semibold underline text-2xl"
          >
            FAQページを見る
          </Link>
        </div>

        {/* 📞 電話でのお問い合わせエリア（max-w-screen-lg） */}
        <div className="max-w-screen-lg mx-auto bg-black text-white p-10 rounded-lg shadow-lg text-center mb-12">
          <h2 className="text-4xl font-bold mb-6">電話でのお問い合わせ</h2>

          {/* 📞 電話番号 */}
          <p className="text-4xl font-bold flex items-center justify-center mb-4">
            <FaPhoneAlt className="mr-3 text-white" />
            <a href="tel:08042677229" className="text-white hover:underline">
              080-4267-7229
            </a>
          </p>
          <p className="text-2xl font-semibold mb-6">（担当：吉村）</p>

          {/* ⏰ 営業時間 */}
          <p className="text-2xl mb-2 flex items-center justify-center">
            <FaClock className="mr-2 text-white" />
            営業時間: <strong>平日 10:00 〜 19:00</strong>
          </p>
          <p className="text-2xl text-gray-400">
            ※ 土日祝のお問い合わせは翌営業日に対応
          </p>
        </div>

        {/* 📋 フォームエリア（max-w-screen-lg） */}
        <div className="max-w-screen-lg mx-auto bg-white p-10 rounded-lg shadow-md">
          <h2 className="text-3xl font-bold mb-6">フォームからお問い合わせ</h2>
          <form
            name="contact"
            method="POST"
            data-netlify="true"
            action="/thanks/"
          >
            <input type="hidden" name="form-name" value="contact" />

            {/* ✅ Netlifyのスパム対策（hiddenフィールド） */}
            <p className="hidden">
              <label htmlFor="bot-field">
                このフィールドには入力しないでください:
              </label>
              <input id="bot-field" name="bot-field" />
            </p>

            {/* 🔹 入力フィールド */}
            {[
              {
                id: 'name',
                label: 'お名前（必須）',
                type: 'text',
                required: true,
              },
              { id: 'company', label: '会社名・団体名（任意）', type: 'text' },
              {
                id: 'email',
                label: 'メールアドレス（必須）',
                type: 'email',
                required: true,
              },
              {
                id: 'phone',
                label: '電話番号（必須）',
                type: 'tel',
                required: true,
              },
              { id: 'website', label: 'WebサイトURL（任意）', type: 'url' },
            ].map(({ id, label, type, required }) => (
              <div key={id} className="border-b border-gray-300 pb-6 mb-6">
                <label
                  htmlFor={id}
                  className="block text-2xl font-semibold mb-1"
                >
                  {label}
                </label>
                <input
                  type={type}
                  id={id}
                  name={id}
                  required={required}
                  className="w-full p-4 border border-gray-300 rounded-lg text-lg"
                />
              </div>
            ))}

            {/* 🔹 お問い合わせ内容（チェックボックス追加） */}
            <div className="border-b border-gray-300 pb-6 mb-6">
              <label
                htmlFor="inquiry-options"
                className="block text-2xl font-semibold mb-2"
              >
                お問い合わせ内容（複数選択可）
              </label>

              <div className="flex flex-col space-y-2 text-2xl">
                <label htmlFor="inquiry-homepage" className="flex items-center">
                  <input
                    id="inquiry-homepage"
                    type="checkbox"
                    name="inquiry[]"
                    value="ホームページ制作"
                    className="mr-2"
                  />
                  ホームページ制作
                </label>
                <label
                  htmlFor="inquiry-marketing"
                  className="flex items-center"
                >
                  <input
                    id="inquiry-marketing"
                    type="checkbox"
                    name="inquiry[]"
                    value="Webマーケティング"
                    className="mr-2"
                  />
                  Webマーケティング
                </label>
                <label htmlFor="inquiry-video" className="flex items-center">
                  <input
                    id="inquiry-video"
                    type="checkbox"
                    name="inquiry[]"
                    value="動画制作"
                    className="mr-2"
                  />
                  動画制作
                </label>
                <label htmlFor="inquiry-trouble" className="flex items-center">
                  <input
                    id="inquiry-trouble"
                    type="checkbox"
                    name="inquiry[]"
                    value="ホームページのトラブル"
                    className="mr-2"
                  />
                  ホームページのトラブル
                </label>
              </div>
            </div>

            {/* 🔹 お問い合わせ内容 */}
            <div>
              <label
                htmlFor="message"
                className="block text-2xl font-semibold mb-1"
              >
                お問い合わせ内容（必須）
              </label>
              <textarea
                id="message"
                name="message"
                rows="5"
                required
                className="w-full p-4 border border-gray-300 rounded-lg text-lg"
              ></textarea>
            </div>

            {/* ✅ プライバシーポリシーの同意チェック */}
            <div className="flex items-center mt-6">
              <input
                type="checkbox"
                id="privacy-policy"
                name="privacy-policy"
                required
                className="mr-2"
              />
              <label htmlFor="privacy-policy" className="text-2xl">
                <a
                  href="/privacy-policy"
                  target="_blank"
                  className="text-blue-600 underline hover:text-blue-800"
                >
                  プライバシーポリシー
                </a>
                に同意します。
              </label>
            </div>

            {/* 🔹 送信ボタン */}
            <button
              type="submit"
              className="w-full py-3 mt-4 bg-black text-white font-semibold text-2xl rounded-lg hover:bg-gray-900"
            >
              送信する
            </button>
          </form>
        </div>
      </section>
      <Breadcrumbs breadcrumbList={breadcrumbList} />
    </Layout>
  );
};

export default ContactPage;
